import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify'
// import Amplify from 'aws-amplify';
import awsconfig from './config';
import awsauth from './awsauth';
import './scss/style.scss';
import { encryptValue } from "src/views/components/ApiHelper";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Email App
const TheEmailApp = React.lazy(() => import('./views/apps/email/TheEmailApp'));

// Pages
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      signedIn: JSON.parse(localStorage.getItem('signedIn')) || null,
    }
  }
  componentDidMount () {
    // Amplify.configure(awsconfig)
    Auth.configure({ oauth: awsauth })

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          // getUserData(data['signInUserSession']['idToken']['payload']['sub']).then(value => {console.log('getUserData User: ',value)})
          localStorage.setItem('accessToken',data['signInUserSession']['accessToken']['jwtToken'])
          this.setState({ signedIn: true},() => {
            const requestOptions = {
              method: 'GET',
              headers: { 'Content-Type': 'application/json' },
            };
            localStorage.setItem('signedIn', JSON.stringify(this.state.signedIn));
            localStorage.setItem('sub', JSON.stringify(data['signInUserSession']['idToken']['payload']['sub']));
            localStorage.setItem('username', JSON.stringify(data['signInUserSession']['idToken']['payload']['cognito:username']));
            fetch(process.env.REACT_APP_USERS_API + data['signInUserSession']['idToken']['payload']['sub'], requestOptions)
              .then((response) => {
                   if (response.ok) {
                      return response.json();
                    } else {
                      throw new Error('Something went wrong');
                    }
              })
              .then(
                  (result) => {
                      encryptValue(result['role']).then(data => {
                        localStorage.setItem('role',data)
                      });                      
                      encryptValue(result['final_permission']).then(data => {
                        localStorage.setItem('finalPermission',data)
                      });
                      localStorage.setItem('currentAccountCount', result['current_account_count']);
                      localStorage.setItem('planExpirationDate', result['plan_expiration_date']);
                      localStorage.setItem('planStartDate', result['plan_start_date']);
                      localStorage.setItem('plan', result['plan']);
                      localStorage.setItem('parentId', JSON.stringify(result['parent_id'] ? result['parent_id'] : result['id']));
                      localStorage.setItem('organisationId', JSON.stringify(result['organisationId'] ? result['organisationId'] : result['id']));
                      localStorage.setItem('firstName', JSON.stringify(result['first_name'] ? result['first_name'] : ""));
                      localStorage.setItem('lastName', JSON.stringify(result['last_name'] ? result['last_name'] : ""));
                      localStorage.setItem('provider', JSON.stringify(result['provider'] ? result['provider'] : ""));
                  },
              ).catch((error) => {
                  console.log("error");
              });
          })
          break
        case 'signOut':
          localStorage.clear();
          this.setState({ signedIn: false})

          this.setState({ user: null })
          window.location.href= "https://"+ awsauth.domain +"/logout?client_id="+ awsconfig.Auth.userPoolWebClientId +"&response_type=token&scope="+ awsauth.scope.join('+') +"&state=STATE&redirect_uri="+awsauth.redirectSignIn
          break
        default:
          return null;
      }
    })
  }
  render() {
    const { signedIn } = this.state;

    const LoginUrl = "https://"+ awsauth.domain +"/login?client_id="+ awsconfig.Auth.userPoolWebClientId +"&response_type=token&scope="+ awsauth.scope.join('+') +"&redirect_uri="+awsauth.redirectSignIn
    if (!signedIn) {
      // Auth.configure({ oauth: awsauth })
      // Auth.federatedSignIn()
      Auth.currentAuthenticatedUser().then((currentUser) => {
        if('signInUserSession' in currentUser){
            // User Is login
        }else{
          // User is not login
          Auth.configure({ oauth: awsauth })
          Auth.federatedSignIn()
        }

      }).catch((error) => {
        // User is not login
        Auth.configure({ oauth: awsauth })
        Auth.federatedSignIn()
      });
      return false;
    }

    Auth.configure({ oauth: awsauth })
    Auth.currentSession().then((session) => {
      const expirationTime = session.getAccessToken().getExpiration();

        if (expirationTime < (new Date().getTime()/ 1000)) {
          Auth.signOut();
        }
    }).catch((error) => {
        console.log("error ",error);
        Auth.signOut();
    });
    
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
          {(!signedIn) ? (<Route exact path="/*" render={() => (window.location = LoginUrl)} />):
            (<Switch>
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/apps/email" name="Email App" render={props => <TheEmailApp {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>)}
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
