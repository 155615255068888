import axios from 'axios';
import { AES, enc } from 'crypto-js';

export const GetRiskHistoryData = async (AllAccountArn) => {
  const accessToken = localStorage.getItem('accessToken');
    let ResultData = []
    // Get All Aws cloud account details
    // let riskHistoryURL = "https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getRiskHistoryData"
    let riskHistoryURL = process.env.REACT_APP_GET_RISK_HISTORY_DATA_API
    try {
      let arnIdsStr = AllAccountArn.join(',')
      const riskHistoryRequestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
        body: JSON.stringify({ sub: JSON.parse(localStorage.getItem('parentId')), cloudarns: arnIdsStr })
      };
      await fetch(riskHistoryURL, riskHistoryRequestOptions).then(response => response.json()).then(data => {
        ResultData = data.data
      });
    } catch (error) {
      console.log(error)
    }
    return ResultData
}

export const GetMasterRule = async () => {
  const accessToken = localStorage.getItem('accessToken');
  let ResultData = []
  // Get All Rule Master Data from Opensearch
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
  };
  // let ruleURL = "https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getMasterRulesItems"
  let ruleURL = process.env.REACT_APP_GET_MASTER_RULES_ITEMS_API
  try {
    let ruleResponse = await fetch(ruleURL, requestOptions)
    let ruleRes = await ruleResponse.json()
    ResultData = ruleRes.data
  } catch (error) {
    console.log(error)
  }
  return ResultData
}
export const GetMasterError = async () => {
  const accessToken = localStorage.getItem('accessToken');
  let ResultData = []
  // Get All Rule Master Data from Opensearch
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
  };
  // let ruleURL = "https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getMasterErrorcodeItems"
  let ruleURL = process.env.REACT_APP_GET_MASTER_ERRORCODE_ITEMS_API
  try {
    let ruleResponse = await fetch(ruleURL, requestOptions)
    let ruleRes = await ruleResponse.json()
    ResultData = ruleRes.data
  } catch (error) {
    console.log(error)
  }
  return ResultData
}

export const GetAWSArn = async () => {
  const accessToken = localStorage.getItem('accessToken');
  let ResultData = []
  // Get All Aws cloud account details
  // let awsArnURL = "https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getAwsAccounts"
  let awsArnURL = process.env.REACT_APP_GET_AWS_ACCOUNTS_API
  let sub_id = JSON.parse(localStorage.getItem('parentId'))
  try {
    const awsRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
      body: JSON.stringify({ sub: sub_id })
    };
    await fetch(awsArnURL, awsRequestOptions).then(response => response.json()).then(data => {
      ResultData = data.data
    });
  } catch (error) {
    console.log(error)
  }
  return ResultData
}

export const GetAzureArn = async () => {
  const accessToken = localStorage.getItem('accessToken');
  let ResultData = []
  // Get All Azure cloud account details
  // let azureArnURL = "https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getAzureAccounts"
  let azureArnURL = process.env.REACT_APP_GET_AZURE_ACCOUNTS_API
  try {
    const azureRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
      body: JSON.stringify({ sub: JSON.parse(localStorage.getItem('parentId')) })
    };
    await fetch(azureArnURL, azureRequestOptions).then(response => response.json()).then(data => {
      ResultData = data.data
    });
  } catch (error) {
    console.log(error)
  }
  return ResultData
}

export const GetLatestCron = async (AllAccountArn) => {
  const accessToken = localStorage.getItem('accessToken');
  let ResultData = []
  // Get Latest Cron Data
  // let CronURL = "https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getLatestCron"
  let CronURL = process.env.REACT_APP_GET_LATEST_CRON_API
  try {
    let arnIdsStr = AllAccountArn.join(',')
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
      body: JSON.stringify({ sub: JSON.parse(localStorage.getItem('parentId')), 'cloudarns': arnIdsStr })
    };
    await fetch(CronURL, requestOptions).then(response => response.json()).then(data => {
      ResultData = data.data
    });
  } catch (error) {
    console.log(error)
  }
  return ResultData
}

export const GetLatestRuleCheckData = async (RuleMasterIds, LatestCronIds) => {
  const accessToken = localStorage.getItem('accessToken');
  let ResultData = []
  let ruleIdsStr = RuleMasterIds.join(',')
  let cronIdsStr = LatestCronIds.join(',')
  // let rulesCheckURL = "https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getRulesCheckIdsData";
  let rulesCheckURL = process.env.REACT_APP_GET_RULES_CHECK_IDS_DATA_API

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
    body: JSON.stringify({ sub_id: JSON.parse(localStorage.getItem('parentId')), 'ids': ruleIdsStr, 'cron_ids': cronIdsStr })
  };
  await fetch(rulesCheckURL, requestOptions).then(response => response.json()).then(data => { ResultData = data.data });

  return ResultData
}

export const test = async () => {
    let ResultData = []
    return ResultData
}

export const fetchFinalPermission = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
  };
  const sub = localStorage.getItem('sub')
  // const response = await fetch("https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/users/".concat(sub.slice(1,-1)), requestOptions);
  const response = await fetch(process.env.REACT_APP_USERS_API.concat(sub.slice(1,-1)), requestOptions);
  const json = await response.json();
  return json
}

export const getSubuserFinalPermission = async (substring) => {
  const accessToken = localStorage.getItem('accessToken');
  let ResultData = false
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
    body: JSON.stringify({ sub: JSON.parse(localStorage.getItem('sub')),substring:substring })
  };
  // URL = "https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getSubuserFinalPermission"
  URL = process.env.REACT_APP_GET_SUBUSER_FINAL_PERMISSION_API
  await fetch(URL, requestOptions).then(response => response.json())
  .then(data => { ResultData = data.result });
  return ResultData
}

export const enableDisableSubUser = async (sub, username) => {
  try {
    // const url = "https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/enableDisableSubUser"
    const url = process.env.REACT_APP_ENABLE_DISABLE_SUBUSER_API
    const params = {
      sub: sub,
      username: username
    };    
    const response = await axios.post(url, params)
    return response;
  } catch (error) {
      throw error;
  }
}
export const deleteSubUsers = async (sub, username) => {
  try {
    // const url = "https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/deleteSubUsers"
    const url = process.env.REACT_APP_DELETE_SUBUSERS_API
    const params = {
      sub: sub,
      username: username
    };
    const response = await axios.post(url, params)
    return response;
  } catch (error) {
    throw error;
  }
}

export const getUserFinalPermission = async (sub) => {
  try {
    // const url = "https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getUserFinalPermission"
    const url = process.env.REACT_APP_GET_USER_FINAL_PERMISSION_API
    const params = {
      sub: sub,
    };    
    const response = await axios.post(url, params)
    return response;
  } catch (error) {
      throw error;
  }
}

export const getOrganisationOfUser = async (sub, parentId) => {
  const accessToken = localStorage.getItem('accessToken');
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
      body: JSON.stringify({ sub: sub,parentId:parentId })
    };
    // const response = await fetch('https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getOrganisationOfUser',requestOptions);
    const response = await fetch(process.env.REACT_APP_GET_ORGANISATION_OF_USER_API,requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
}
export const getAwsCredsOfOrganisation = async (organisationId) => {
  const accessToken = localStorage.getItem('accessToken');
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
      body: JSON.stringify({ organisationId: organisationId })
    };
    // const response = await fetch('https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getAwsCredsOfOrganisation',requestOptions);
    const response = await fetch(process.env.REACT_APP_GET_AWS_CREDS_OF_ORGANISATION_API,requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const encryptValue = async (value) => {
  const key = 'qwerty';
  const cipherText = AES.encrypt(value, key).toString();
  return cipherText
}
export const decryptValue = async (value) => {
  const key = 'qwerty';
  const bytes = AES.decrypt(value, key);
  const result = bytes.toString(enc.Utf8);
  return result
}

export const getDataByLatestCron = async (organisationId) => {
  const accessToken = localStorage.getItem('accessToken');
  let ResultData = []
  let cronEnd = "N/A"
  // let URL = "https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getOpensearchDataByLatestCron";
  let URL = process.env.REACT_APP_GET_OPENSEARCH_DATA_BY_LATEST_CRON_API;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
    body: JSON.stringify({ organisationId: JSON.parse(localStorage.getItem('parentId')) })
  };
  await fetch(URL, requestOptions).then(response => response.json()).then(data => { 
    ResultData = data.data;
    cronEnd = data.cronEnd;
   });

  return [ResultData, cronEnd];
}

export const getLast30daysDataByCronEndDate = async (organisationId) => {
  const accessToken = localStorage.getItem('accessToken');
  try {
    let ResultData = []
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
      body: JSON.stringify({ organisationId: organisationId })
    };
    // const response = await fetch('https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getLast30daysDataByCronEndDate',requestOptions);
    const response = await fetch(process.env.REACT_APP_GET_LAST30_DAYS_DATA_BY_CRONENDDATE_API,requestOptions);
    const data = await response.json().then(data => { ResultData = data.data });
    return ResultData
  } catch (error) {
    console.error(error);
  }
}

export const getOrganisationData = async (organisationId) => {
  const accessToken = localStorage.getItem('accessToken');
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
      body: JSON.stringify({ organisationId: organisationId })
    };
    // const response = await fetch('https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/getOrganisationData',requestOptions);
    const response = await fetch(process.env.REACT_APP_GET_ORGANISATION_DATA_API,requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const updateCurrentAccountCount = async (organisationId,currentAccountCount) => {
  const accessToken = localStorage.getItem('accessToken');
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
      body: JSON.stringify({ organisationId: organisationId, currentAccountCount:currentAccountCount })
    };
    // const response = await fetch('https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/updateCurrentAccountCount',requestOptions);
    const response = await fetch(process.env.REACT_APP_UPDATE_CURRENT_ACCOUNT_COUNT_API,requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const subBillingListing1 = async (organisationId) => {
  const accessToken = localStorage.getItem('accessToken');
  let ResultData = []
  // let URL = 'https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/subBillingListing'
  let URL = process.env.REACT_APP_SUBBILLING_LISTING_API
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
    body: JSON.stringify({ organisationId: organisationId })
  };
  try{
    const response = await fetch(URL, requestOptions)
    if (response.status === 401) {
      console.log("in 401 token expired:" )
      // 1. remove access token from localstorage
      // localStorage.removeItem('accessToken');
      // 2. redirect to sign page
    } else {
      const data = await response.json();
      ResultData = data.data;
    }
  } catch (error) {
    console.log("token expired: ", error);
  }
  return ResultData
  // await fetch(URL, requestOptions).then(response => response.json()).then(data => { ResultData = data.data });
}

export const subBillingListing = async (organisationId) => {
  const accessToken = localStorage.getItem('accessToken');
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
      body: JSON.stringify({ organisationId: organisationId })
    };
    const response = await fetch(process.env.REACT_APP_SUBBILLING_LISTING_API,requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
}


export const getSelectedAccountCronDetails = async (organisationId,cloudAccountIds,accountType) => {
  const accessToken = localStorage.getItem('accessToken');
  let ResultData = [];
  let greatestCron_item = ""
  let IsCrondisabled = ""
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
      body: JSON.stringify({ organisationId: organisationId,cloudAccountIds:cloudAccountIds,accountType:accountType })
    };
    const response = await fetch(process.env.REACT_APP_GET_SELECTED_ACCOUNT_CRON_DETAILS_API,requestOptions);
    const data = await response.json();
    greatestCron_item = data.greatestCron_item;
    IsCrondisabled = data.is_cron_disabled;
  } catch (error) {
    console.error(error);
  }
  return {"greatestCron_item":greatestCron_item,"IsCrondisabled":IsCrondisabled}
}

export const validateAwsArn = async (RoleArn,ExternalId) => {
  const accessToken = localStorage.getItem('accessToken');
  let is_valid = ""
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
      body: JSON.stringify({ RoleArn: RoleArn,ExternalId:ExternalId })
    };
    // const response = await fetch("https://nthablt0ng.execute-api.eu-west-1.amazonaws.com/DEV/validateAwsArn",requestOptions);
    const response = await fetch(process.env.REACT_APP_VALIDATEAWSARN_API,requestOptions);
    const data = await response.json();
    is_valid = data.is_valid;
  } catch (error) {
    console.error(error);
  }
  return {"is_valid":is_valid}
}